import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"

const Horlogerie = () => {
  return (
    <>
      <Layout>
        <SEO title="Horlogerie - Genève"/>

        <div className="slideshow">
          <div className="usp">
            <div className="usp-txt-header">Horlogerie</div>
          </div>
        </div>
        <div className="border-gold-bottom"></div>

        <h3>Montres neuves et d'occasion</h3>

        <section className="section-flex-04">

          <div className="section-flex-03">

            <div className="gold-box-small">
              <Link to="/montres-neuves-et-d-occasion" className="gold-box-small-text" style={{textDecoration:'none'}}>
                <div className="gold-box-small-img-Bagues" style={{fontSize: '0.94em'}}> Montres neuves et d'occasion </div>
              </Link>
            </div>

          </div>

        </section>

        <h3>Notre savoir faire</h3>

        <section className="section-flex-04">

          <div className="section-flex-03">

            <div className="gold-box-small">
              <Link to="/savoirfaireHorlogeries#reparation_de_montres" className="gold-box-small-text" style={{textDecoration:'none'}}>
                <div className="gold-box-small-img-Bagues">Réparations de montres</div>
              </Link>
            </div>

            <div className="gold-box-small">
              <Link to="/savoirfaireHorlogeries#entretiens_et_nettoyages" className="gold-box-small-text" style={{textDecoration:'none'}}>
                <div className="gold-box-small-img-Bagues">Entretiens et Nettoyages</div>
              </Link>
            </div>

            <div className="gold-box-small">
              <Link to="/savoirfaireHorlogeries#changements_de_piles" className="gold-box-small-text" style={{textDecoration:'none'}}>
                <div className="gold-box-small-img-Bagues">Changements de piles</div>
              </Link>
            </div>

            <div className="gold-box-small">
              <Link to="/savoirfaireHorlogeries#restaurations" className="gold-box-small-text" style={{textDecoration:'none'}}>
                <div className="gold-box-small-img-Bagues">Restaurations</div>
              </Link>
            </div>

            <div className="gold-box-small">
              <Link to="/savoirfaireHorlogeries#changements_de_bracelets" className="gold-box-small-text" style={{textDecoration:'none'}}>
                <div className="gold-box-small-img-Bagues">Changements de bracelets</div>
              </Link>
            </div>

            <div className="gold-box-small">
              <Link to="/savoirfaireHorlogeries#conciergerie" className="gold-box-small-text" style={{textDecoration:'none'}}>
                <div className="gold-box-small-img-Bagues">Conciergerie</div>
              </Link>
            </div>

            <div className="gold-box-small">
              <Link to="/savoirfaireHorlogeries#rachat" className="gold-box-small-text" style={{textDecoration:'none'}}>
                <div className="gold-box-small-img-Bagues">Rachat de montres toutes marques & dépôt-vente</div>
              </Link>
            </div>

          </div>

        </section>


        <h3>Montres</h3>

        <section className="section-flex-04">

          <div className="section-flex-03">

            <div className="gold-box-small">
              <Link to="/horlogerie_yvan_monnet" className="gold-box-small-text" style={{textDecoration:'none'}}>
                <div className="gold-box-small-img-Bagues">Yvan Monnet</div>
              </Link>
            </div>

            <div className="gold-box-small">
              <Link to="/horlogerie_grovana" className="gold-box-small-text" style={{textDecoration:'none'}}>
                <div className="gold-box-small-img-Bagues">Grovana</div>
              </Link>
            </div>

            <div className="gold-box-small">
              <Link to="/horlogerie_maserati" className="gold-box-small-text" style={{textDecoration:'none'}}>
                <div className="gold-box-small-img-Bagues">Maserati</div>
              </Link>
            </div>

            <div className="gold-box-small">
              <Link to="/horlogerie_gvchiani" className="gold-box-small-text" style={{textDecoration:'none'}}>
                <div className="gold-box-small-img-Bagues">Gvchiani Genève</div>
              </Link>
            </div>


          </div>

        </section>

        <h3>Les Bracelets</h3>

        <section className="section-flex-04">

          <div className="section-flex-03">

            <div className="gold-box-small">
              <Link to="/bracelets_everestbands" className="gold-box-small-text" style={{textDecoration:'none'}}>
                <div className="gold-box-small-img-Bagues">Bracelets Everestbands</div>
              </Link>
            </div>

            <h3>Les Remontoirs pour montres automatiques & Rangements</h3>

            <div className="gold-box-small">
              <Link to="/swisskubik" className="gold-box-small-text" style={{textDecoration:'none'}}>
                <div className="gold-box-small-img-Bagues">Swisskubik</div>
              </Link>
            </div>

            <div className="gold-box-small">
              <Link to="/scatola_del_tempo" className="gold-box-small-text" style={{textDecoration:'none'}}>
                <div className="gold-box-small-img-Bagues">Scatola del Tempo</div>
              </Link>
            </div>

          </div>

        </section>

      </Layout>
    </>
  )
}

export default Horlogerie
